import React, {useEffect} from "react"
import {useForm, useFormState} from "react-final-form"
import {FormattedMessage, useIntl} from "react-intl"
import {openBridge} from "../../utils/bridge"
import {SignupSocialError} from "../../utils/errors"
import Button from "../button"
import {useInitialData} from "../../hooks/use-initial-data"
import View from "../view"
import {captureMessage, withScope} from "@sentry/browser"

const {REACT_APP_CONNECT_URL, REACT_APP_ADMIN_URL} = process.env

export default ({...props}) => {
  const {locale} = useIntl()
  const {submitting, submitSucceeded} = useFormState({
    subscription: {submitting: true},
  })
  const {mutators} = useForm()
  const {initialSocialError} = useInitialData()

  const handleClick = (platform) => {
    window.gtag("event", `signup_${platform}`, {event_category: "signup"})

    if (window.ReactNativeWebView) {
      window.location = `${
        process.env[`REACT_APP_BASENAME_${locale.toUpperCase()}`]
      }/${platform}`
      return
    }

    mutators.setError(null)
    mutators.toggleSubmit(true)

    openBridge(
      `${
        process.env[`REACT_APP_BASENAME_${locale.toUpperCase()}`]
      }/${platform}`,
      (token) => {
        const connectRoute = `${REACT_APP_CONNECT_URL}/${locale}/login/sso?_target_path=${encodeURIComponent(
          `${REACT_APP_ADMIN_URL}`
        )}&token=${encodeURIComponent(token)}`
        // send ga event
        mutators.toggleSubmit(false)

        window.gtag("event", `signup_${platform}_ok`, {
          event_category: "signup",
        })

        window.location = connectRoute
      },
      (error) => {
        withScope((scope) => {
          if (error.id) {
            scope.setExtra("id", error.id)
          }
          captureMessage(error.message)
        })

        window.gtag("event", `signup_${platform}_error`, {
          event_category: "signup",
        })

        if (error instanceof SignupSocialError) {
          mutators.setError(error)
        }
        mutators.toggleSubmit(false)
      }
    )
  }

  useEffect(() => {
    if (initialSocialError) {
      mutators.setError(new SignupSocialError(initialSocialError))
    }
  }, [mutators, initialSocialError])

  return (
    <View
      display="flex"
      flexDirection={{xs: "column", m: "row"}}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Button
        disabled={submitting || submitSucceeded}
        onClick={() => handleClick("facebook")}
        palette="facebook"
        mb={{xs: 2, m: 0}}
        py={{xs: 2, m: 1}}
        flex="1"
        textAlign="center"
        maxWidth="250px"
      >
        <FormattedMessage id="signup.form.facebook" />
      </Button>
    </View>
  )
}
