import React from "react"

export const InitialDataContext = React.createContext()

export const InitialDataProvider = ({initialData, children}) => {
  return (
    <InitialDataContext.Provider value={initialData}>
      {children}
    </InitialDataContext.Provider>
  )
}
