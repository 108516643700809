import React from "react"
import {FormattedMessage} from "react-intl"

import Button from "../button"
import Computer from "../computer"
import List from "../list"
import Title from "../title"
import View from "../view"

const {PUBLIC_URL} = process.env

export default (props) => (
  <View id="start" display="flex" flexDirection="column" {...props}>
    <Title mb={{xs: 2, m: 4}}>
      <FormattedMessage id="signup.start.title">
        {(text) => (
          <View as="h2" m={0} fontSize="inherit" fontWeight="normal">
            {text}
          </View>
        )}
      </FormattedMessage>
    </Title>
    <View
      display="flex"
      justifyContent="center"
      alignItems="center"
      px={3}
      pb={3}
    >
      <Computer
        src={PUBLIC_URL + "/assets/screen-blog.png"}
        mr={4}
        display={{xs: "none", m: "block"}}
      />
      <List>
        <li>
          <FormattedMessage id="signup.start.1" />
        </li>
        <li>
          <FormattedMessage id="signup.start.2" />
        </li>
        <li>
          <FormattedMessage id="signup.start.3" />
        </li>
        <li>
          <FormattedMessage id="signup.start.4" />
        </li>
      </List>
    </View>
    <View display="flex" justifyContent="center" mt={3}>
      <Button
        palette="primary"
        onClick={() => {
          window.scrollTo(0, 0)
          window.gtag("event", "signup_create_blog_middle", {
            event_category: "signup",
          })
        }}
      >
        <FormattedMessage id="signup.start.create_blog" />
      </Button>
    </View>
  </View>
)
