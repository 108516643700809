import {configureScope, init, Integrations} from "@sentry/browser"
import {ExtraErrorData} from "@sentry/integrations"
import {EXPECTED_ERRORS} from "../utils/errors"
import {stringToBool} from "../utils/stringToBool"

const {
  REACT_APP_RELEASE_HASH,
  REACT_APP_SENTRY_DSN,
  REACT_APP_ENABLE_SENTRY,
  REACT_APP_BUILD_ENV,
  APP_BRAND,
} = process.env

export const initSentry = () => {
  const isSentryEnable = stringToBool(REACT_APP_ENABLE_SENTRY)
  if (isSentryEnable) {
    init({
      release: REACT_APP_RELEASE_HASH || REACT_APP_BUILD_ENV,
      dsn: REACT_APP_SENTRY_DSN,
      integrations: [
        new ExtraErrorData(),
        new Integrations.Breadcrumbs({
          console: false,
          fetch: false,
          xhr: false,
        }),
      ],
      environment: REACT_APP_BUILD_ENV,
      beforeSend(event, hint) {
        const error = hint.originalException

        // Filter out a cryptic IE11 error :shrug:
        if (
          error &&
          error.name === "ReferenceError" &&
          error.message.includes("$")
        ) {
          return null
        }

        // Filter out expected errors
        if (EXPECTED_ERRORS.includes(error)) {
          return null
        }

        return event
      },
    })

    configureScope((scope) => {
      scope.setTag("language", navigator.language)
      scope.setTag("brand", APP_BRAND)
      if (document.referrer) {
        scope.setExtra("referrer", document.referrer)
      }
    })
  }
}
