import React from "react"
import {FormattedMessage} from "react-intl"

import View from "./view"

export default props => (
  <View display="flex" flexDirection="column" {...props}>
    <View as="p" textAlign="center" px={3}>
      <FormattedMessage id="signup.policy.cgu1" />
    </View>
    <View as="p" textAlign="center" px={3}>
      <FormattedMessage id="signup.policy.cgu2" />
    </View>
  </View>
)
