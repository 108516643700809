import React, {useEffect} from "react"

import Header from "../header"
import Policy from "../policy"
import View from "../view"

import Form from "./form"

const Invitation = () => {
  useEffect(() => {
    window.gtag("event", "signupInvitation", {event_category: "signup"})
  }, [])

  return (
    <View
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      position="relative"
      minHeight="100vh"
      justifyContent="space-between"
    >
      <Header>
        <Form />
      </Header>
      <Policy fontSize={{xs: 0, m: 3}} pb={4} />
    </View>
  )
}

export default Invitation
