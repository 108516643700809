import styled, {keyframes} from "styled-components"
import {Spinner} from "../icons/Spinner"

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`

export const Loader = styled(Spinner)`
  animation: ${spin} 2s linear infinite;
`
