import styled from "styled-components"
import {palette} from "styled-tools"

import View from "./view"

export default styled(View).attrs(() => ({
  as: "img",
  height: {xs: 60, s: 60, m: 90},
}))`
  border-radius: 50%;
  border: 2px solid ${palette("black")};
`
