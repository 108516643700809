import React, {useMemo} from "react"
import {strongPasswordMatcher} from "../utils/strong-password-matcher"
import View from "./view"

export const COLORS = [
  "rgb(237, 237, 237)",
  "darkred",
  "orangered",
  "orange",
  "yellowgreen",
  "green",
]

export const PasswordStrengthBar = ({password, ...props}) => {
  const strength = useMemo(() => {
    let score = 0

    if (password.length) {
      score++
    }

    if (
      password.match(/^.*(?=.*[a-z]).*$/) &&
      password.match(/^.*(?=.*[A-Z]).*$/)
    ) {
      score++
    }

    if (
      password.match(/^.*(?=.*[0-9]).*$/) &&
      password.match(/^.*(?=.*[^a-zA-Z0-9]).*$/)
    ) {
      score++
    }

    if (password.length >= 8) {
      score++
    }

    if (strongPasswordMatcher.test(password) && password.length >= 12) {
      score++
    }

    return score
  }, [password])

  return (
    <View display="flex" flexDirection="row" alignItems="center" {...props}>
      <View
        flex="1"
        display="flex"
        flexDirection="row"
        height={4}
        overflow="hidden"
        borderRadius={9999}
      >
        <View flex="1" background={COLORS[strength]} />
        <View
          ml={1}
          flex="1"
          background={COLORS[strength > 1 ? strength : 0]}
        />
        <View
          ml={1}
          flex="1"
          background={COLORS[strength > 2 ? strength : 0]}
        />
        <View
          ml={1}
          flex="1"
          background={COLORS[strength > 3 ? strength : 0]}
        />
        <View
          ml={1}
          flex="1"
          background={COLORS[strength > 4 ? strength : 0]}
        />
      </View>

      <View
        as="svg"
        ml={1}
        viewBox="0 0 448 512"
        xmlns="http://www.w3.org/2000/svg"
        fill={COLORS[strength]}
        stroke={COLORS[strength]}
        width={16}
        height={16}
      >
        {strength >= 4 ? (
          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
        ) : (
          <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
        )}
      </View>
    </View>
  )
}
