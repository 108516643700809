import React from "react"
import {useIntl} from "react-intl"

const {
  REACT_APP_BUILD_ENV = "dev",
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_GOOGLE_TAG_KEY,
  REACT_APP_HOTJAR_ID,
  REACT_APP_DISABLE_RECAPTCHA,
} = process.env

export const Scripts = () => {
  const {locale} = useIntl()
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', "${REACT_APP_GOOGLE_ANALYTICS_KEY}");
            gtag("require", "linkid", "linkid.js")
            gtag("set", "anonymizeIp", true)
            gtag("set", "forceSSL", true)
            gtag("set", "dimension2", "${locale}")
        `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.hj = window.hj || function() {
                (window.hj.q = window.hj.q || []).push(arguments)
            };
            window._hjSettings = {
                hjid: ${REACT_APP_HOTJAR_ID},
                hjsv: 6
            };
        `,
        }}
      />
      {!REACT_APP_DISABLE_RECAPTCHA && (
        <script src={`https://www.google.com/recaptcha/api.js`} async />
      )}
      {REACT_APP_BUILD_ENV !== "dev" && (
        <>
          <script
            src={`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GOOGLE_ANALYTICS_KEY}`}
            async
          />
          <script
            src={`https://www.googletagmanager.com/gtm.js?id=${REACT_APP_GOOGLE_TAG_KEY}`}
            async
          />
          <script
            src={`https://static.hotjar.com/c/hotjar-${REACT_APP_HOTJAR_ID}.js?sv=6`}
            async
          />
        </>
      )}
    </>
  )
}
