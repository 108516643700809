import React from "react"
import {FormattedMessage} from "react-intl"

import Button from "../button"
import Computer from "../computer"
import Image from "../image"
import Title from "../title"
import View from "../view"

const {PUBLIC_URL} = process.env

export default (props) => (
  <View display="flex" flexDirection="column" {...props}>
    <Title mb={{xs: 3, m: 4}}>
      <FormattedMessage id="signup.advantages.publish_your_ideas">
        {(text) => (
          <View as="h2" m={0} fontSize="inherit" fontWeight="normal">
            {text}
          </View>
        )}
      </FormattedMessage>
    </Title>
    <View
      display="flex"
      flexDirection={{xs: "column", m: "row"}}
      justifyContent="center"
      alignItems="center"
      px={3}
      pb={3}
    >
      <Computer
        src={PUBLIC_URL + "/assets/screen-stats.png"}
        mr={4}
        display={{xs: "none", m: "block"}}
      />
      <View display="flex" flexDirection="column">
        <View display="flex" flexDirection="column">
          <View display="flex" mb={3}>
            <Image src={PUBLIC_URL + "/assets/yoga.png"} height="60px" />
            <View
              ml={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <View fontSize={4} mb={1}>
                <FormattedMessage id="signup.advantages.time_saver">
                  {(text) => (
                    <View as="h3" m={0} fontSize="inherit" fontWeight="normal">
                      {text}
                    </View>
                  )}
                </FormattedMessage>
              </View>
              <View fontSize={2}>
                <FormattedMessage id="signup.advantages.easy_blog_creation" />
              </View>
            </View>
          </View>
          <View display="flex" mb={3}>
            <Image src={PUBLIC_URL + "/assets/binoculars.png"} height="60px" />
            <View
              ml={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <View fontSize={4} mb={1}>
                <FormattedMessage id="signup.advantages.visible_on_google">
                  {(text) => (
                    <View as="h3" m={0} fontSize="inherit" fontWeight="normal">
                      {text}
                    </View>
                  )}
                </FormattedMessage>
              </View>
              <View fontSize={2}>
                <FormattedMessage id="signup.advantages.you_will_be_visible_on_google" />
              </View>
            </View>
          </View>
          <View display="flex">
            <Image src={PUBLIC_URL + "/assets/buoy.png"} height="60px" />
            <View
              ml={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <View fontSize={4} mb={1}>
                <FormattedMessage id="signup.advantages.on_your_side">
                  {(text) => (
                    <View as="h3" m={0} fontSize="inherit" fontWeight="normal">
                      {text}
                    </View>
                  )}
                </FormattedMessage>
              </View>
              <View fontSize={2}>
                <FormattedMessage id="signup.advantages.livechat_support" />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
    <View display="flex" justifyContent="center">
      <Button
        palette="primary"
        mt={3}
        onClick={() => {
          window.scrollTo(0, 0)
          window.gtag("event", "signup_create_blog_middle", {
            event_category: "signup",
          })
        }}
      >
        <FormattedMessage id="signup.advantages.create_blog" />
      </Button>
    </View>
  </View>
)
