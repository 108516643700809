import React, {createContext, useMemo} from "react"
import {ThemeProvider as StyledThemeProvider} from "styled-components"
import {renderTheme} from "../utils/theme/render-theme"

export const ThemeContext = createContext(null)

export const ThemeProvider = ({children, branding}) => {
  const theme = renderTheme(branding)

  const contextValue = useMemo(
    () => ({
      theme,
    }),
    [theme]
  )

  return (
    <ThemeContext.Provider value={contextValue}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  )
}
