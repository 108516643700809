import React from "react"
import {FormattedMessage} from "react-intl"
import Button from "../button"
import Title from "../title"
import View from "../view"

export default (props) => (
  <View id="hosting" display="flex" flexDirection="column" {...props}>
    <Title palette="primary" mb={{xs: 2, m: 4}}>
      <FormattedMessage id="signup.hosting.title">
        {(text) => (
          <View as="h2" m={0} fontSize="inherit" fontWeight="normal">
            {text}
          </View>
        )}
      </FormattedMessage>
    </Title>
    <View
      display="flex"
      justifyContent="center"
      alignItems="center"
      px={3}
      pb={3}
      flexDirection="column"
    >
      <p>
        <FormattedMessage id="signup.hosting.1" />
      </p>
      <p>
        <FormattedMessage id="signup.hosting.2" />
      </p>
      <p>
        <FormattedMessage id="signup.hosting.3" />
      </p>
    </View>
    <View display="flex" justifyContent="center" mt={3}>
      <Button
        palette="primary"
        onClick={() => {
          window.scrollTo(0, 0)
          window.gtag("event", "signup_create_blog_middle", {
            event_category: "signup",
          })
        }}
      >
        <FormattedMessage id="signup.hosting.create_blog" />
      </Button>
    </View>
  </View>
)
