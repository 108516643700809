import {FORM_ERROR} from "final-form"
import React, {useEffect, useState} from "react"
import {Field, Form, useField, useForm} from "react-final-form"
import {FormattedMessage, useIntl} from "react-intl"
import styled from "styled-components"
import {palette, theme} from "styled-tools"
import {Check} from "../../icons/Check"
import {Email} from "../../icons/Email"
import {
  EMAIL_ERRORS,
  ERRORS,
  HOSTNAME_ERRORS,
  PASSWORD_ERRORS,
  RecaptchaError,
  SignupError,
  SignupInvitationError,
  SignupSocialError,
} from "../../utils/errors"
import {post} from "../../utils/post"
import {sanitizeSubDomain} from "../../utils/sanitize-sub-domain"
import {strongPasswordMatcher} from "../../utils/strong-password-matcher"
import {validateRecaptcha} from "../../utils/validate-recaptcha"
import Button from "../button"
import {FieldError} from "../field-error"
import {useInitialData} from "../../hooks/use-initial-data"
import Input from "../input"
import {Link} from "../link"
import {Loader} from "../loader"
import {PasswordStrengthBar} from "../password-strength-bar"
import Separator from "../separator"
import View from "../view"
import SocialButtons from "./social-buttons.js"

const {REACT_APP_RECAPTCHA_SITE_KEY, PUBLIC_URL, REACT_APP_DOMAIN} = process.env

const Domain = styled(View)`
  border-left: 1px solid ${palette("gray", 2)};
`

const SelectDomain = styled(View).attrs({as: "select"})`
  border: none;
  font-size: ${theme("fontSizes.3")}px;
  color: ${palette("grayText")};
  cursor: pointer;

  &:focus-visible {
    border: none;
    outline: none;
  }
`

const Success = ({email}) => {
  return (
    <View
      palette="white"
      opaque
      position="absolute"
      display="flex"
      flexDirection="column"
      top={0}
      bottom={0}
      left={0}
      right={0}
      alignItems="center"
      justifyContent="space-around"
      textAlign="center"
      p={{xs: 2, s: 3}}
    >
      <View display="flex" flexDirection="column" alignItems="center">
        <Check color="#44c4a1" size={80} />
        <View mt={1} fontSize={5}>
          <FormattedMessage id={`signup.success.blog_created`} />
        </View>
      </View>
      <View display="flex" flexDirection="column" alignItems="center">
        <Email color="#cccccc" size={80} />
        <View fontSize={5}>
          <FormattedMessage id="signup.success.mail_sent" />
          <br />
          <small>{email}</small>
        </View>
      </View>

      <View fontSize={4}>
        <FormattedMessage id="signup.success.instructions" />
      </View>

      <View fontSize={2}>
        <FormattedMessage id="signup.success.help" />
        <br />
        <FormattedMessage
          id="signup.success.contact"
          values={{
            contactLink: (chunk) => (
              <View as="a" href="mailto:support@overblog.com">
                {chunk}
              </View>
            ),
          }}
        />
      </View>
    </View>
  )
}

const FormContent = ({
  handleSubmit,
  submitting,
  invalid,
  submitError,
  submitSucceeded,
  values,
  availableBaseHostDomains,
}) => {
  const intl = useIntl()
  const {mutators} = useForm()
  const {initialInvitationError} = useInitialData()
  const {
    input: {value: domain},
  } = useField("domain")

  useEffect(() => {
    if (initialInvitationError) {
      mutators.setError(new SignupInvitationError(initialInvitationError))
    }
  }, [mutators, initialInvitationError])

  return (
    <View
      display="flex"
      flexDirection="column"
      textAlign="center"
      p={{xs: 2, s: 3}}
      borderRadius={6}
      palette="white"
      opaque
      position="relative"
    >
      <View fontSize={3} mb={{xs: 2, s: 3}} palette="grayText">
        <FormattedMessage id="signup.form.create_blog_30_seconds" />
      </View>

      <View
        as="form"
        method="POST"
        display="flex"
        flexDirection="column"
        onSubmit={handleSubmit}
        aria-busy={submitting}
        aria-invalid={invalid}
      >
        <div
          id="recaptcha"
          className="g-recaptcha"
          data-sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
          data-size="invisible"
          data-callback="recaptchaSuccessCallback"
          data-expired-callback="recaptchaExpiredCallback"
          data-error-callback="recaptchaErrorCallback"
        />

        <Field name="email">
          {({input, meta, ...props}) => (
            <>
              <Input
                {...input}
                {...props}
                aria-invalid={meta.invalid}
                autoFocus
                className="data-hj-whitelist"
                type="email"
                palette="gray"
                placeholder={intl.formatMessage({
                  id: "signup.form.email",
                })}
                required
                icon={PUBLIC_URL + "/assets/envelope.svg"}
              />
              <FieldError
                visible={meta.submitError && !meta.dirtySinceLastSubmit}
              >
                {meta.submitError && !meta.dirtySinceLastSubmit && (
                  <FormattedMessage id={`signup.errors.${meta.submitError}`} />
                )}
              </FieldError>
            </>
          )}
        </Field>
        <Field
          name="password"
          validate={(value) =>
            strongPasswordMatcher.test(value) ? undefined : ERRORS.WEAK_PASSWORD
          }
        >
          {({input, meta, ...props}) => {
            const showError =
              (meta.error && meta.dirty) ||
              (meta.submitError && !meta.dirtySinceLastSubmit)
            return (
              <>
                <Input
                  {...input}
                  {...props}
                  type="password"
                  mt={2}
                  palette="gray"
                  placeholder={intl.formatMessage({
                    id: "signup.form.password",
                  })}
                  autoComplete="new-password"
                  icon={PUBLIC_URL + "/assets/key.svg"}
                  required
                />
                {meta.dirty && (
                  <PasswordStrengthBar password={input.value} mx={2} mt={1} />
                )}
                <FieldError visible={showError}>
                  {showError && (
                    <FormattedMessage
                      id={`signup.errors.${meta.error || meta.submitError}`}
                    />
                  )}
                </FieldError>
              </>
            )
          }}
        </Field>

        <Field name="hostname" format={sanitizeSubDomain}>
          {({input, meta, ...props}) => (
            <>
              <View
                display="flex"
                mt={2}
                palette="gray"
                opaque
                borderRadius={32}
              >
                <View display="flex" flex="1">
                  <Input
                    {...input}
                    {...props}
                    aria-invalid={meta.invalid}
                    className="data-hj-whitelist"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "signup.form.hostname",
                    })}
                    width="100%"
                    palette="gray"
                    required
                    autoComplete="off"
                    icon={PUBLIC_URL + "/assets/globe.svg"}
                    pattern="[A-Za-z](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?"
                    title={intl.formatMessage({
                      id: "signup.form.hostname.pattern",
                    })}
                  />
                </View>
                <Field name="domain">
                  {({input, meta, ...props}) => (
                    <Domain
                      palette="grayText"
                      display="flex"
                      flexDirection="column"
                      alignItems="stretch"
                      justifyContent="center"
                      pl={1}
                      pr={2}
                    >
                      <SelectDomain
                        {...input}
                        {...props}
                        aria-invalid={meta.invalid}
                      >
                        {availableBaseHostDomains.map((domain) => (
                          <option key={domain} value={domain}>
                            .{domain}
                          </option>
                        ))}
                      </SelectDomain>
                    </Domain>
                  )}
                </Field>
              </View>

              <FieldError visible={input.value} palette="gray" tone={1}>
                {input.value && (
                  <FormattedMessage
                    id={`signup.form.hostname.preview`}
                    values={{
                      hostname: `${input.value}.${domain}`,
                    }}
                  />
                )}
              </FieldError>
              <FieldError
                visible={meta.submitError && !meta.dirtySinceLastSubmit}
              >
                {meta.submitError && !meta.dirtySinceLastSubmit && (
                  <FormattedMessage id={`signup.errors.${meta.submitError}`} />
                )}
              </FieldError>
            </>
          )}
        </Field>

        <Button
          type="submit"
          disabled={submitting || submitSucceeded}
          palette="primary"
          mt={2}
        >
          {submitting || submitSucceeded ? (
            <Loader size={16} />
          ) : (
            <FormattedMessage id="signup.form.submit" />
          )}
        </Button>
      </View>

      <FieldError visible={submitError instanceof SignupError}>
        {submitError instanceof SignupError && (
          <FormattedMessage id={`signup.error`} values={{id: submitError.id}} />
        )}
      </FieldError>
      <FieldError visible={submitError instanceof SignupInvitationError}>
        {submitError instanceof SignupInvitationError && (
          <FormattedMessage id={`signup.error`} values={{id: submitError.id}} />
        )}
      </FieldError>

      <Separator fontSize={{xs: 1, l: 2, xl: 3}} palette="grayText" mt={2}>
        <FormattedMessage id="signup.form.or_create_blog_with" />
      </Separator>

      <FieldError visible={submitError instanceof SignupSocialError}>
        {submitError instanceof SignupSocialError && (
          <FormattedMessage
            id={
              submitError.message === ERRORS.EMAIL_ALREADY_USED
                ? "signup.errors.EMAIL_ALREADY_USED"
                : "signup.error"
            }
            values={{id: submitError.id}}
          />
        )}
      </FieldError>

      <SocialButtons mt={2} />

      <View
        justifyContent="center"
        palette="grayText"
        mt={2}
        fontSize={{xs: 0, m: 1, l: 2}}
      >
        <FormattedMessage
          id="signup.form.cgu"
          values={{
            submit: intl.formatMessage({id: "signup.form.submit"}),
            termOfSaleLink: (
              <Link href="https://www.over-blog.com/terms-of-sale">
                <FormattedMessage id="signup.form.terms-of-sale" />
              </Link>
            ),
            cookiesLink: (
              <Link href="https://www.over-blog.com/cookies">
                <FormattedMessage id="signup.form.cookies" />
              </Link>
            ),
            privacyPolicyLink: (
              <Link href="https://www.over-blog.com/privacy-policy">
                <FormattedMessage id="signup.form.privacy-policy" />
              </Link>
            ),
          }}
        />
      </View>
      {submitSucceeded && <Success email={values.email} />}
    </View>
  )
}

export default () => {
  const intl = useIntl()
  const [availableBaseHostDomains, setAvailableBaseHostDomains] = useState([REACT_APP_DOMAIN])

  useEffect(() => {
    setAvailableBaseHostDomains(window.AppProps.availableBaseHostDomains)
  }, [])

  const onSubmit = async (values) => {
    let recaptchaResponse

    try {
      recaptchaResponse = await validateRecaptcha()
    } catch (error) {
      window.gtag("event", "signup_error", {event_category: "signup"})
      return {[FORM_ERROR]: new RecaptchaError(error)}
    }

    try {
      await post(
        `${
          process.env[`REACT_APP_BASENAME_${intl.locale.toUpperCase()}`]
        }/signup`,
        {
          _recaptcha: recaptchaResponse,
          ...values,
        }
      )

      window.gtag("event", "signup_ok", {event_category: "signup"})
    } catch (error) {
      const {message} = error

      if (EMAIL_ERRORS.includes(message)) {
        return {email: message}
      }
      if (PASSWORD_ERRORS.includes(message)) {
        return {password: message}
      }
      if (HOSTNAME_ERRORS.includes(message)) {
        return {hostname: message}
      }

      window.gtag("event", "signup_error", {event_category: "signup"})

      return {[FORM_ERROR]: new SignupError(error)}
    }
  }

  return (
    <Form
      mutators={{
        toggleSubmit: ([submitting], state) => {
          state.formState.submitting = submitting
        },
        setError: ([error], state) => {
          state.formState.submitError = error
        },
      }}
      initialValues={{
        email: "",
        password: "",
        hostname: "",
        domain: availableBaseHostDomains[0],
      }}
      onSubmit={onSubmit}
    >
      {(props) => <FormContent availableBaseHostDomains={availableBaseHostDomains} {...props} />}
    </Form>
  )
}
