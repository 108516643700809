import {addBreadcrumb, captureMessage, captureException} from "@sentry/browser"

const {REACT_APP_DISABLE_RECAPTCHA} = process.env

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

function getRecaptchaVisibleDeferred() {
  const deferred = new Deferred()

  const targetElement = document.body

  const observerConfig = {
    childList: true,
    attributes: false,
    attributeOldValue: false,
    characterData: false,
    characterDataOldValue: false,
    subtree: false,
  }

  function DOMChangeCallbackFunction(mutationRecords) {
    mutationRecords.forEach(mutationRecord => {
      if (mutationRecord.addedNodes.length) {
        const reCaptchaParentContainer = mutationRecord.addedNodes[0]
        const reCaptchaIframe = reCaptchaParentContainer.querySelectorAll(
          'iframe[title="recaptcha challenge"]'
        )

        if (reCaptchaIframe.length) {
          const reCaptchaChallengeOverlayDiv =
            reCaptchaParentContainer.firstChild
          if (reCaptchaChallengeOverlayDiv) {
            deferred.resolve(reCaptchaChallengeOverlayDiv)
          }
        }
      }
    })
  }

  const reCaptchaObserver = new MutationObserver(DOMChangeCallbackFunction)
  reCaptchaObserver.observe(targetElement, observerConfig)

  deferred.promise
    .catch(error => {
      // swallow empty rejections
      if (error !== undefined) {
        throw error
      }
    })
    .finally(() => reCaptchaObserver.disconnect())

  return deferred
}

export const validateRecaptcha = async () => {
  if (REACT_APP_DISABLE_RECAPTCHA) {
    return "fake-recaptcha-token"
  }

  addBreadcrumb({
    category: "recaptcha",
    message: "start recaptcha",
  })

  try {
    return await new Promise((resolve, reject) => {
      const recaptchaVisibleDeferred = getRecaptchaVisibleDeferred()

      recaptchaVisibleDeferred.promise
        .then(reCaptchaOverlayDiv => {
          const reCaptchaClosureObserver = new MutationObserver(() => {
            if (
              reCaptchaOverlayDiv.parentElement.style.visibility === "hidden" &&
              !window.grecaptcha.getResponse()
            ) {
              // TADA!! Do something here as the challenge was either closed by hitting outside of an overlay div OR by pressing ESC key
              reCaptchaClosureObserver.disconnect()
              reject()
            }
          })
          reCaptchaClosureObserver.observe(reCaptchaOverlayDiv.parentElement, {
            attributes: true,
            attributeFilter: ["style"],
          })
        })
        .catch(error => {
          // swallow empty rejections
          if (error !== undefined) {
            captureException(error)
          }
        })

      window.grecaptcha.reset()
      window.grecaptcha.execute()

      window.recaptchaSuccessCallback = () => {
        addBreadcrumb({
          category: "recaptcha",
          message: "recaptcha success",
        })
        recaptchaVisibleDeferred.reject()
        resolve(window.grecaptcha.getResponse())
      }

      const errorHandler = () => {
        captureMessage("recaptcha error")
        recaptchaVisibleDeferred.reject()
        reject()
      }

      window.recaptchaExpiredCallback = errorHandler
      window.recaptchaErrorCallback = errorHandler
    })
  } finally {
    window.recaptchaExpiredCallback = window.recaptchaErrorCallback = window.recaptchaSuccessCallback = () => {}
  }
}
