import styled from "styled-components"

import {
  style,
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography,
} from "styled-system"

import {withProp, palette} from "styled-tools"

const clipPath = style({
  prop: "clipPath",
})
const whiteSpace = style({
  prop: "whiteSpace",
})
const textDecoration = style({
  prop: "textDecoration",
})
const objectFit = style({
  prop: "objectFit",
})

const View = styled("div")`
  background-color: ${withProp(
    ["opaque", "palette", "tone"],
    (opaque, paletteProp, tone = 0) => {
      if (!opaque) {
        return "unset"
      }
      return palette(paletteProp, tone, "unset")
    }
  )};
  color: ${withProp(
    ["color", "opaque", "palette", "tone"],
    (color, opaque, paletteProp, tone = 0) =>
      color
        ? color
        : palette(opaque ? `${paletteProp}Text` : paletteProp, tone, "inherit")
  )};
  ${color}
  ${typography}
  ${flexbox}
  ${layout}
  ${grid}
  ${background}
  ${border}
  ${position}
  ${shadow}
  ${opacity}
  ${space}

  ${clipPath}
  ${whiteSpace}
  ${objectFit}
  ${textDecoration}
`

export default View
