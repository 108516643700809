import React from "react"
import {FormattedMessage} from "react-intl"

import Title from "../title"
import Diagonal from "../diagonal"
import View from "../view"
import Testimonial from "./testimonial"

const {PUBLIC_URL} = process.env

export default props => (
  <View display="flex" flexDirection="column" {...props}>
    <Diagonal palette="blue" tone={1} />
    <View palette="blue" tone={1} opaque px={3} py={{xs: 1, m: 2, l: 4}}>
      <Title mb={{xs: 3, m: 4}} palette="blue">
        <FormattedMessage id="signup.testimonials.decoration.title">
          {text => (
            <View as="h2" m={0} fontSize="inherit" fontWeight="normal">
              {text}
            </View>
          )}
        </FormattedMessage>
      </Title>
      <View
        display="flex"
        flexDirection={{xs: "column", m: "row"}}
        justifyContent="center"
        flexWrap={{xs: "nowrap", m: "wrap"}}
        width={{xs: null, m: 700, l: 1100}}
        m="auto"
      >
        <Testimonial
          image={PUBLIC_URL + "/testimonials/decoration/1.jpg"}
          text="signup.testimonials.decoration.1.text"
          author="signup.testimonials.decoration.1.author"
          flex={{xs: "auto", m: "50%"}}
          pr={{xs: 0, m: 3}}
          mb={3}
        />
        <Testimonial
          image={PUBLIC_URL + "/testimonials/decoration/2.png"}
          text="signup.testimonials.decoration.2.text"
          author="signup.testimonials.decoration.2.author"
          flex={{xs: "auto", m: "50%"}}
          pl={{xs: 0, m: 3}}
          mb={3}
        />
      </View>
    </View>
    <Diagonal top palette="blue" tone={1} />
  </View>
)
