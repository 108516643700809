import styled from "styled-components"

const SvgIcon = styled("svg").attrs(() => ({"aria-hidden": true}))`
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  user-select: none;
  flex-shrink: 0;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`

SvgIcon.defaultProps = {
  size: 20,
}

export default SvgIcon
