import styled from "styled-components"
import {ifNotProp, palette, prop} from "styled-tools"

import View from "./view"

export default styled(View)`
  height: 50px;
  background-image: linear-gradient(
    to ${ifNotProp("top", "left top", "right bottom")},
    ${palette(prop("palette", "primary"), prop("tone", 0))},
    ${palette(prop("palette", "primary"), prop("tone", 0))} 51%,
    transparent 52%,
    transparent
  );
`
