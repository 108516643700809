import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter} from "react-router-dom"
import App from "./components/app"

import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import {initSentry} from "./services/sentry"

initSentry()

import(
  `./locales/${window.AppProps.branding}/${window.AppProps.locale}.json`
).then((messages) => {
  ReactDOM.hydrate(
    <BrowserRouter
      basename={
        process.env[
          `REACT_APP_BASENAME_${window.AppProps.locale.toUpperCase()}`
        ]
      }
    >
      <App initialData={window.AppProps} messages={messages} />
    </BrowserRouter>,
    document.getElementById("root")
  )
})
