import {addBreadcrumb, captureMessage, withScope} from "@sentry/browser"

export const post = async (url, data) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  const result = await response.json()

  addBreadcrumb({
    type: "http",
    category: "fetch",
    data: {
      url,
      status_code: result.status,
      method: "POST",
      data,
      result,
    },
  })

  if (response.ok) {
    return result
  } else {
    withScope(scope => {
      if (result.id) {
        scope.setExtra("id", result.id)
      }
      captureMessage(result.message)
    })
    throw result
  }
}
