import styled, {css} from "styled-components"
import View from "./view"

export const FieldError = styled(View)`
  transition: font-size 100ms ease, margin-top 100ms ease,
    opacity 250ms 100ms ease;
  ${({visible, theme, mt, fontSize}) =>
    css`
      margin-top: ${visible ? theme.space[mt] : 0}px;
      font-size: ${visible ? theme.fontSizes[fontSize] : 0}px;
      opacity: ${visible ? "1" : "0"};
    `}
`

FieldError.defaultProps = {
  ...View.defaultProps,
  palette: "red",
  fontSize: 2,
  mt: 1,
}
