import {FORM_ERROR} from "final-form"
import React from "react"
import {Field, Form} from "react-final-form"
import {FormattedMessage, useIntl} from "react-intl"
import {useParams} from "react-router-dom"
import {ERRORS, PASSWORD_ERRORS, SignupError} from "../../utils/errors"
import {post} from "../../utils/post"
import {strongPasswordMatcher} from "../../utils/strong-password-matcher"
import Button from "../button"
import {FieldError} from "../field-error"
import {useInitialData} from "../../hooks/use-initial-data"
import Input from "../input"
import {Link} from "../link"
import {Loader} from "../loader"
import {PasswordStrengthBar} from "../password-strength-bar"
import View from "../view"

const {REACT_APP_CONNECT_URL, REACT_APP_ADMIN_URL, PUBLIC_URL} = process.env

export default () => {
  const intl = useIntl()
  const {invitationToken} = useParams()
  const {blog, email} = useInitialData()

  const onSubmit = async (values) => {
    try {
      const {token} = await post(
        `${
          process.env[`REACT_APP_BASENAME_${intl.locale.toUpperCase()}`]
        }/invitation`,
        {
          invitationToken,
          ...values,
        }
      )

      const connectRoute = `${REACT_APP_CONNECT_URL}/${
        intl.locale
      }/login/sso?_target_path=${encodeURIComponent(
        `${REACT_APP_ADMIN_URL}/${blog.id}`
      )}&token=${encodeURIComponent(token)}`

      window.gtag("event", "signupInvitation_ok", {event_category: "signup"})

      window.location = connectRoute
    } catch (error) {
      const {message} = error

      if (PASSWORD_ERRORS.includes(message)) {
        return {password: message}
      }

      window.gtag("event", "signupInvitation_error", {event_category: "signup"})
      return {[FORM_ERROR]: new SignupError(error)}
    }
  }

  return (
    <View
      display="flex"
      flexDirection="column"
      textAlign="center"
      p={{xs: 2, s: 3}}
      borderRadius={6}
      palette="white"
      opaque
    >
      <View fontSize={3} mb={{xs: 2, s: 3}} palette="grayText">
        <FormattedMessage
          id="invitation.form.join"
          values={{
            blogName: blog.name || blog.host,
          }}
        />
      </View>

      <Form
        initialValues={{
          password: "",
        }}
        onSubmit={onSubmit}
      >
        {({handleSubmit, submitting, submitSucceeded, submitError}) => {
          return (
            <View
              as="form"
              method="POST"
              display="flex"
              flexDirection="column"
              onSubmit={handleSubmit}
            >
              <Input
                disabled
                required
                className="data-hj-whitelist"
                name="email"
                type="email"
                palette="gray"
                placeholder={intl.formatMessage({id: "signup.form.email"})}
                autoComplete="email"
                defaultValue={email}
                icon={PUBLIC_URL + "/assets/envelope.svg"}
              />

              <Field
                name="password"
                validate={(value) =>
                  strongPasswordMatcher.test(value)
                    ? undefined
                    : ERRORS.WEAK_PASSWORD
                }
              >
                {({input, meta, ...props}) => {
                  const showError =
                    (meta.error && meta.dirty) ||
                    (meta.submitError && !meta.dirtySinceLastSubmit)
                  return (
                    <>
                      <Input
                        {...input}
                        {...props}
                        type="password"
                        mt={2}
                        palette="gray"
                        placeholder={intl.formatMessage({
                          id: "signup.form.password",
                        })}
                        autoComplete="new-password"
                        required
                        icon={PUBLIC_URL + "/assets/key.svg"}
                      />
                      {meta.dirty && (
                        <PasswordStrengthBar
                          password={input.value}
                          mx={2}
                          mt={1}
                        />
                      )}
                      <FieldError visible={showError}>
                        {showError && (
                          <FormattedMessage
                            id={`signup.errors.${
                              meta.error || meta.submitError
                            }`}
                          />
                        )}
                      </FieldError>
                    </>
                  )
                }}
              </Field>
              <Button
                disabled={submitting || submitSucceeded}
                palette="primary"
                mt={2}
              >
                {submitting || submitSucceeded ? (
                  <Loader size={16} />
                ) : (
                  <FormattedMessage id="signup.form.submit" />
                )}
              </Button>
              <FieldError visible={submitError}>
                {submitError && (
                  <FormattedMessage
                    id={`signup.error`}
                    values={{id: submitError.id}}
                  />
                )}
              </FieldError>
            </View>
          )
        }}
      </Form>
      <View
        mt={2}
        justifyContent="center"
        palette="gray"
        tone={1}
        fontSize={{xs: 0, m: 1, l: 2}}
      >
        <FormattedMessage
          id="signup.form.cgu"
          values={{
            submit: intl.formatMessage({id: "invitation.form.submit"}),
            termOfSaleLink: (
              <Link href="https://www.over-blog.com/terms-of-sale">
                <FormattedMessage id="signup.form.terms-of-sale" />
              </Link>
            ),
            cookiesLink: (
              <Link href="https://www.over-blog.com/cookies">
                <FormattedMessage id="signup.form.cookies" />
              </Link>
            ),
            privacyPolicyLink: (
              <Link href="https://www.over-blog.com/privacy-policy">
                <FormattedMessage id="signup.form.privacy-policy" />
              </Link>
            ),
          }}
        />
      </View>
    </View>
  )
}
