import React from "react"
import styled from "styled-components"
import {palette} from "styled-tools"

import View from "./view"

const Children = styled(View)`
  background: ${palette("white")};
`

const Wrapper = styled(View)`
  background: linear-gradient(
    180deg,
    transparent calc(50% - 1px),
    ${palette("gray", 2)} calc(50%),
    transparent calc(50% + 1px)
  );
`

export default ({children, ...props}) => (
  <Wrapper display="flex" justifyContent="center" {...props}>
    {children && <Children px={1}>{children}</Children>}
  </Wrapper>
)
