export class SignupError extends Error {
  constructor({id, message}) {
    super(message)
    this.id = id
  }
}

export class SignupSocialError extends Error {
  constructor({id, message}) {
    super(message)
    this.id = id
  }
}

export class SignupInvitationError extends Error {
  constructor({id, message}) {
    super(message)
    this.id = id
  }
}

export class BridgeError extends Error {}

export class RecaptchaError extends Error {}

export const ERRORS = {
  BRIDGE_CLOSED: "BRIDGE_CLOSED",
  BRIDGE_BLOCKED: "BRIDGE_BLOCKED",
  BRIDGE_ERROR: "BRIDGE_ERROR",
  EMAIL_ALREADY_USED: "EMAIL_ALREADY_USED",
  INVALID_EMAIL: "INVALID_EMAIL",
  WEAK_PASSWORD: "WEAK_PASSWORD",
  INVALID_HOSTNAME: "INVALID_HOSTNAME",
}

export const EMAIL_ERRORS = [ERRORS.EMAIL_ALREADY_USED, ERRORS.INVALID_EMAIL]
export const PASSWORD_ERRORS = [ERRORS.WEAK_PASSWORD]
export const HOSTNAME_ERRORS = [ERRORS.INVALID_HOSTNAME]

export const EXPECTED_ERRORS = [
  // Application errors
  ERRORS.BRIDGE_CLOSED,
  ERRORS.BRIDGE_BLOCKED,
  ...EMAIL_ERRORS,
  ...PASSWORD_ERRORS,
  ...HOSTNAME_ERRORS,
  // Recaptcha errors
  "incorrect-captcha-sol",
]
