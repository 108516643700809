import styled from "styled-components"

import View from "./view"

export default styled(View).attrs({
  as: "button",
  px: {xs: 3, s: 4},
  py: 2,
  borderRadius: 32,
  fontSize: 3,
  opaque: true,
})`
  border: none;
  appearance: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  outline: none;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
