export const COLOR_BLACK = "#2b363b"
export const COLOR_WHITE = "#fff"
export const BREAKPOINTS = ["479px", "1024px", "1200px", "1920px"]
export const FONT_SIZES = [10, 12, 14, 16, 20, 24, 34, 48, 77]
export const SPACES = [0, 10, 16, 32, 64, 128]
export const CATEGORIES_COLORS = {
  cooking: "#fade01",
  diy: "#19a8dd",
  cinema: "#e0183e",
  sport: "#27b803",
  lifestyle: "#ff6400",
  fashion: "#fb71e4",
  tv: "#dd0000",
  travel: "#06d9ff",
  music: "#c17cdc",
  education: "#0683ff",
  literature: "#64d2e8",
  design: "#1ecd6d",
}
