import {BridgeError, SignupSocialError, ERRORS} from "./errors"

const {REACT_APP_DOMAIN} = process.env

const FRAME_CONFIG =
  "location=0," +
  "menubar=0," +
  "status=0," +
  "toolbar=0," +
  "resizable=1," +
  "scrollbars=0," +
  "width=800," +
  "height=600"

export const openBridge = (url, onSuccess, onError) => {
  const frame = window.open(url, "bridge", FRAME_CONFIG)
  if (frame === null) {
    // popup has been blocked
    setTimeout(() => onError(new BridgeError(ERRORS.BRIDGE_BLOCKED)))
    return
  }

  const removeEvents = () => {
    window.removeEventListener("message", onMessage)
    clearInterval(timerID)
  }

  const onMessage = (event) => {
    if (
      !event.origin.endsWith(REACT_APP_DOMAIN) ||
      event.data.source !== "social-signup-bridge"
    ) {
      return
    }

    removeEvents()

    const action = event.data

    if (!action) {
      onError(new BridgeError(ERRORS.BRIDGE_ERROR))
    } else if (action.type === "success") {
      onSuccess(action.payload)
    } else if (action.type === "error") {
      onError(new SignupSocialError(action.payload))
    }
  }

  window.addEventListener("message", onMessage)

  const monitor = () => {
    if (frame.closed) {
      removeEvents()
      onError(new BridgeError(ERRORS.BRIDGE_CLOSED))
    }
  }
  const timerID = setInterval(monitor, 100)

  frame.focus()
}
