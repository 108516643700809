import React from "react"
import {FormattedMessage} from "react-intl"
import View from "../view"
import styled from "styled-components"
import {palette} from "styled-tools"

const FooterLink = styled("span")`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${palette("primary")};
  }
`

const links = [
  {
    transKey: "footer.link.about-us",
    url: "/about-us/background",
  },
  {
    transKey: "footer.link.who-uses-overblog",
    url: "/who-use-over-blog/creatives",
  },
  {
    transKey: "footer.link.tips",
    url: "/12-seo-tips/choose-an-editorial-line",
  },
  {
    transKey: "footer.link.FAQ",
    url: "/faq/create-blog-for-free",
  },
  {
    transKey: "footer.link.features",
    url: "/features/social-hub",
  },
  {
    transKey: "footer.link.legal-notice",
    url: "/legal-notice",
  },
  {
    transKey: "footer.link.terms-of-use",
    url: "/terms-of-use",
  },
  {
    transKey: "footer.link.terms-of-sale",
    url: "/terms-of-sale",
  },
  {
    transKey: "footer.link.cookies",
    url: "/cookies",
  },
  {
    transKey: "footer.link.privacy-policy",
    url: "/privacy-policy",
  },
  {
    transKey: "footer.link.cookie-preferences",
    url: "javascript:Didomi.notice.show()",
  },
]

const Link = ({transKey, isNotLast, url, className}) => {
  return (
    <>
      <FooterLink
        data-url={Buffer.from(url).toString("base64")}
        className={className}
      >
        <FormattedMessage id={transKey} />
      </FooterLink>
      {isNotLast && (
        <View display="flex" whiteSpace="pre">
          {" | "}
        </View>
      )}
    </>
  )
}

const FooterLinks = () => {
  return (
    <View
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      mb={{xs: 3, m: 2}}
      px={3}
    >
      {links.map((link, index) => (
        <Link
          key={index}
          transKey={link.transKey}
          isNotLast={links.length - 1 !== index}
          url={link.url}
          className="js-b16"
        />
      ))}
    </View>
  )
}

export default FooterLinks
