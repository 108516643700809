import styled from "styled-components"
import {palette, prop, theme} from "styled-tools"

import View from "./view"

const List = styled(View).attrs(() => ({
  as: "ol",
  fontSize: 3,
  pl: 4,
  opaque: true,
}))`
  background: none;
  font-size: ${theme("fontSizes.3")}px;
  counter-reset: ordered;
  list-style-type: none;

  & > li {
    position: relative;

    &::before {
      background: ${palette(prop("palette"), prop("tone"))};
      color: ${palette(`${prop("palette")}Text`, prop("tone"))};
      border-radius: 100%;
      content: counter(ordered);
      counter-increment: ordered;
      display: flex;
      font-weight: bold;
      height: ${theme("space.3")}px;
      justify-content: center;
      left: -${theme("space.4")}px;
      line-height: ${theme("space.3")}px;
      margin-left: ${theme("space.1")}px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: ${theme("space.3")}px;
    }
  }

  & > li + li {
    margin-top: 50px;
  }
`

List.defaultProps = {
  ...View.defaultProps,
  palette: "primary",
  tone: 1,
}

export default List
