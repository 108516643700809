import React, {useEffect, useState} from "react"
import {useIntl} from "react-intl"
import {isFR} from "../../utils/is-fr"
import Header from "../header"
import Policy from "../policy"
import View from "../view"
import Advantages from "./advantages"
import Autopromo from "./autopromo"
import Copyright from "./copyright"
import Cost from "./cost"
import FooterLinks from "./footer-links"
import Form from "./form"
import Hosting from "./hosting"
import Migration from "./migration"
import Revenues from "./revenues"
import Start from "./start"
import TestimonialsCinema from "./testimonials-cinema"
import TestimonialsCooking from "./testimonials-cooking"
import TestimonialsDecoration from "./testimonials-decoration"
import {OB_BRANDING} from "../../constants/branding"
import {useTheme} from "../../hooks/use-theme"

const {APP_BRAND} = process.env

const Signup = () => {
  const {locale} = useIntl()
  const {theme} = useTheme()
  const [branding, setBranding] = useState()

  useEffect(() => {
   setBranding(window.AppProps.branding);
  }, []);

  useEffect(() => {
    window.gtag("event", "signup", {event_category: "signup"})
  }, [])

  useEffect(() => {
    const obfuscateLinks = window.document.querySelectorAll(".js-b16")
    obfuscateLinks.forEach((obfuscateLink) => {
      const textContent = obfuscateLink.textContent
      const url = Buffer.from(obfuscateLink.dataset.url, "base64").toString(
        "utf-8"
      )
      const a = window.document.createElement("a")
      a.setAttribute("href", url)
      a.setAttribute("class", "xXx ob-footer-link")
      a.textContent = textContent
      obfuscateLink.parentNode.replaceChild(a, obfuscateLink)
    })
  }, [])

  return (
    <>
      <View
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        position="relative"
        minHeight="100vh"
        justifyContent="space-between"
      >
        <Header>
          <Form />
        </Header>
        <View
          display="flex"
          flexDirection="column"
          borderBottom={`10px solid ${theme.palette.primary[0]}`}
        >
          {(branding === OB_BRANDING || APP_BRAND === OB_BRANDING ) && (
            <>
              <Advantages mt={2} mb={{xs: 2, m: 3, l: 5}} />
              <TestimonialsCooking mb={{xs: 2, m: 3, l: 5}} />
              <Start mb={{xs: 2, m: 3, l: 5}} />
              <TestimonialsDecoration mb={{xs: 2, m: 3, l: 5}} />
              <Revenues mb={{xs: 2, m: 3, l: 5}} />
              <TestimonialsCinema mb={{xs: 2, m: 3, l: 5}} />
              <Hosting mb={{xs: 2, m: 3, l: 5}} />
              <Cost />
              <Migration mt={-47} mb={{xs: 2, m: 3, l: 5}} />
              <Autopromo />
            </>
          )}
          <Policy fontSize={{xs: 0, m: 3}} pb={3} />
          {!isFR(locale) && <FooterLinks />}
          <Copyright />
        </View>
      </View>
    </>
  )
}

export default Signup
