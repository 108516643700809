import React from "react"
import {FormattedMessage} from "react-intl"
import styled from "styled-components"
import {theme} from "styled-tools"

import Avatar from "../avatar"
import View from "../view"

const Text = styled(View)`
  line-height: ${theme("space.3")}px;
`

export default ({image, text, author, ...props}) => (
  <View display="flex" flexDirection="column" alignItems="center" {...props}>
    <Avatar src={image} mb={2} />
    <Text mb={2} textAlign="center">
      <FormattedMessage id={text} />
    </Text>
    <View mb={2} palette="grayText" fontStyle="italic">
      <FormattedMessage id={author} />
    </View>
  </View>
)
