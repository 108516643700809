import React from "react"
import {FormattedMessage, useIntl} from "react-intl"
import View from "../view"

const webediaLinks = {
  fr: "https://www.webedia-group.com/",
  en: "https://www.webedia-group.com/",
  de: "https://webedia-group.de/",
  es: "https://webedia-group.es/",
  it: "https://www.webedia-group.com/",
}

const Copyright = () => {
  const {locale} = useIntl()

  return (
    <View mb={2} textAlign="center">
      <FormattedMessage
        id="footer.copyright"
        values={{
          currentYear: new Date().getFullYear(),
          webediaLink: (chunk) => (
            <a
              href={webediaLinks[locale]}
              target="_blank"
              className="ob-footer-link"
            >
              {chunk}
            </a>
          ),
        }}
      />
    </View>
  )
}

export default Copyright
