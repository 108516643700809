import React from "react"
import {IntlProvider} from "react-intl"
import {Redirect, Route, Switch} from "react-router-dom"
import {createGlobalStyle} from "styled-components"
import {palette} from "styled-tools"
import {InitialDataProvider} from "../providers/initial-data-provider.js"
import Invitation from "./invitation/invitation.js"
import {Scripts} from "./scripts.js"
import Signup from "./signup/signup.js"
import {ThemeProvider} from "../providers/theme-provider"

const {APP_BRAND} = process.env

const GlobalStyle = createGlobalStyle`
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    margin: 0;
    color: ${palette("grayText")}
  }

  * {
    box-sizing: border-box;

    &::after,
    &::before {
      box-sizing: inherit;
    }
  }

  .ob-footer-link {
    color: ${palette("grayText")}
  }

  .ob-footer-link:visited {
    color: ${palette("grayText")}
  }

  .ob-footer-link:hover {
    color: ${palette("primary")};
  }
`

const App = ({initialData, messages}) => {
  return (
    <InitialDataProvider initialData={initialData}>
      <IntlProvider locale={initialData.locale} messages={messages}>
        <ThemeProvider branding={APP_BRAND || initialData.branding}>
          <>
            <GlobalStyle />
            <Scripts />
            <Switch>
              <Route
                path="/invitation/:invitationToken"
                component={Invitation}
              />
              <Route exact path="/" component={Signup} />
              <Redirect to="/" />
            </Switch>
          </>
        </ThemeProvider>
      </IntlProvider>
    </InitialDataProvider>
  )
}

export default App
