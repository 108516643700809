import React from "react"
import styled, {css, keyframes} from "styled-components"
import {FormattedMessage} from "react-intl"

import View from "./view"

const categories = [
  "cooking",
  "diy",
  "cinema",
  "sport",
  "lifestyle",
  "fashion",
  "tv",
  "travel",
  "music",
  "education",
  "literature",
  "design",
]

const Slider = styled(View)`
  display: inline-flex;
  flex-direction: column-reverse;
  height: 1.15em;
  overflow: hidden;
`

const slider = keyframes`
    0% {
      transform:translate3d(0, 0, 0);
    }
  
    ${Array.from(
      {length: categories.length},
      (v, i) => css`
        ${(100 / categories.length) * (i + 1) - 4}%,
        ${(100 / categories.length) * (i + 1) + 4}% {
          transform: translate3D(0, ${i * 100}%, 0);
        }
      `
    )}
  `

const SliderItem = styled(View)`
  animation-name: ${slider};
  animation-duration: ${categories.length * 3}s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
`

export default () => (
  <Slider p={0} m={0} ml="0.3em">
    {categories.map((category) => (
      <SliderItem key={category} palette={category}>
        <FormattedMessage id={`header.category.${category}`} />
      </SliderItem>
    ))}
  </Slider>
)
