import {CB_BRANDING, EB_BRANDING} from "../../constants/branding"
import canalblogTheme from "./canalblog"
import overblogTheme from "./overblog"
import eklablogTheme from "./eklablog";

export const renderTheme = (branding) => {
  switch (branding) {
    case CB_BRANDING:
      return canalblogTheme
    case EB_BRANDING:
      return eklablogTheme
    default:
      return overblogTheme
  }
}
