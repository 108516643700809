import React from "react"
import {FormattedMessage} from "react-intl"

import Button from "../button"
import Title from "../title"
import View from "../view"

export default (props) => (
  <View display="flex" flexDirection="column" {...props}>
    <Title mb={{xs: 3, m: 4}}>
      <FormattedMessage id="signup.policy.first_european_blogging_platform">
        {(text) => (
          <View as="h2" m={0} fontSize="inherit" fontWeight="normal">
            {text}
          </View>
        )}
      </FormattedMessage>
    </Title>
    <View display="flex" justifyContent="center">
      <Button
        palette="primary"
        mb={3}
        onClick={() => {
          window.scrollTo(0, 0)
          window.gtag("event", "signup_create_blog_middle", {
            event_category: "signup",
          })
        }}
      >
        <FormattedMessage id="signup.policy.create_blog" />
      </Button>
    </View>
  </View>
)
