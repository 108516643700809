import React from "react"
import styled from "styled-components"
import {palette, prop} from "styled-tools"

import View from "./view"

const Border = styled(View)`
  transform: translateX(-50%);
  background: ${palette(prop("palette"), prop("tone"))};
`

const Title = ({children, palette, tone, ...props}) => (
  <View
    display="flex"
    justifyContent="center"
    fontSize={{xs: 5, m: 7}}
    textAlign="center"
    position="relative"
    p={2}
    pb={3}
    {...props}
  >
    {children}
    <Border
      position="absolute"
      bottom={0}
      left="50%"
      width={100}
      height={3}
      palette={palette}
      tone={tone}
    />
  </View>
)

Title.defaultProps = {
  palette: "primary",
  tone: 0,
}

export default Title
