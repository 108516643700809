import styled from "styled-components"
import {prop, theme, palette} from "styled-tools"

import View from "./view"

export default styled(View).attrs({
  as: "input",
  border: 0,
  pr: 2,
  pl: 2,
  py: 2,
  borderRadius: 32,
  fontSize: "inherit",
  opaque: true,
  palette: "gray",
})`
  outline: none;
  line-height: 1;

  &:disabled {
    color: ${palette("grayTextDisabled")};
  }

  @media (min-width: ${({theme}) => theme.breakpoints[0]}) {
    background-image: url(${prop("icon")});
    background-repeat: no-repeat;
    background-size: auto 40%;
    background-position: ${theme("space.2")}px 50%;
    padding-left: ${props => props.theme.space[3] + props.theme.space[2]}px;
  }
`
