import React from "react"

import Image from "./image"
import View from "./view"

const {PUBLIC_URL} = process.env

const Computer = ({src, ...props}) => (
  <View position="relative" {...props}>
    <Image src={PUBLIC_URL + "/assets/imac.svg"} height="230px" />
    <View position="absolute" top={10} left={10} right={10}>
      <Image src={src} width="100%" />
    </View>
  </View>
)

export default Computer
