import {
  COLOR_WHITE,
  COLOR_BLACK,
  CATEGORIES_COLORS,
  SPACES,
  FONT_SIZES,
  BREAKPOINTS,
} from "./base"

const overblogTheme = {
  breakpoints: BREAKPOINTS,
  fontSizes: FONT_SIZES,
  space: SPACES,
  palette: {
    black: COLOR_BLACK,
    blackText: COLOR_WHITE,
    blue: ["#5874B9", "rgba(88, 118, 193, 0.12)"],
    blueText: [COLOR_WHITE, COLOR_BLACK],
    facebook: "#3b5998",
    facebookText: COLOR_WHITE,
    gray: ["#ededed", "#707070", "#cccccc", "#f3f5f7"],
    grayText: ["#2b363b", COLOR_BLACK, COLOR_BLACK, COLOR_BLACK],
    grayTextDisabled: "rgba(52, 71, 79, 0.5)",
    primary: ["#ff7600", "rgba(255, 118, 0, 0.12)"],
    primaryText: [COLOR_WHITE, COLOR_BLACK],
    red: "#922e2e",
    shadow: "rgba(0, 0, 0, 0.25)",
    twitter: "#00aced",
    twitterText: COLOR_WHITE,
    white: COLOR_WHITE,
    whiteText: COLOR_BLACK,
    // Categories
    ...CATEGORIES_COLORS,
  },
}
overblogTheme.breakpoints.xs = 0
overblogTheme.breakpoints.s = overblogTheme.breakpoints[0]
overblogTheme.breakpoints.m = overblogTheme.breakpoints[1]
overblogTheme.breakpoints.l = overblogTheme.breakpoints[2]
overblogTheme.breakpoints.xl = overblogTheme.breakpoints[3]

export default overblogTheme
