import React from "react"
import {FormattedMessage} from "react-intl"
import Button from "../button"
import Diagonal from "../diagonal"
import Title from "../title"
import View from "../view"

export default (props) => (
  <View id="cost" display="flex" flexDirection="column" {...props}>
    <Diagonal palette="blue" tone={1} />
    <View palette="blue" tone={1} opaque px={3} py={{xs: 1, m: 2, l: 4}}>
      <Title palette="blue" mb={{xs: 2, m: 4}}>
        <FormattedMessage id="signup.cost.title">
          {(text) => (
            <View as="h2" m={0} fontSize="inherit" fontWeight="normal">
              {text}
            </View>
          )}
        </FormattedMessage>
      </Title>
      <View
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={3}
        pb={3}
      >
        <p>
          <FormattedMessage id="signup.cost.1" />
        </p>
      </View>
      <View display="flex" justifyContent="center" mt={3}>
        <Button
          palette="primary"
          onClick={() => {
            window.scrollTo(0, 0)
            window.gtag("event", "signup_create_blog_middle", {
              event_category: "signup",
            })
          }}
        >
          <FormattedMessage id="signup.cost.create_blog" />
        </Button>
      </View>
    </View>
    <Diagonal top palette="blue" tone={1} />
  </View>
)
